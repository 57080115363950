import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from "react-router-dom";

export default function BasicBreadcrumbs(props: { root?: string, primary?: string, url?: string }) {

  return (
    <div role="presentation" /*onClick={(event) => handleClick(event, props?.url)}*/>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink to={`${props?.url || '/home'}`}>
          {props.root ? props.root : 'Home'}
        </NavLink>
        <Typography color="text.primary">{props.primary}</Typography>
      </Breadcrumbs>
    </div>
  );
}
