import * as React from 'react';
import { DataGrid, GridColDef, GridActionsCellItem, GridRenderCellParams} from '@mui/x-data-grid';
import { useLoaderData, useNavigation, NavLink/*, useParams, useLocation*/ } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

// Parasail imports
import { ApiKey } from "react-app-env";
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import {  FetchKeyAPI } from "service/FetchService";
import { Logger } from "utils/Logger";
import GeneralCreateKey from "components/views/common/GeneralCreateKey";
import CustomNoRowsOverlay from "components/tables/CustomNoRowsOverlay";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const GeneralKeysView = () => {
  const [open, setOpen] = React.useState(false);
  const [currentKey, setCurrentKey] = React.useState<ApiKey>(Object);
  const [currentKeys, setCurrentKeys] = React.useState(Array<ApiKey>);

  const keys = useLoaderData() as any[];
  const navigation = useNavigation();

  React.useEffect(() => {
    if (keys) {
      setCurrentKeys(keys);
    }
  }, [keys]);

  // React.useEffect(() => {
  //   // set the pass through state data (e.g., DedicatedDeployment object)
  //   const model = location?.state;
  //   if (model) {
  //     setDedicatedDeployment(model);
  //   }
  // }, [location]);

  if (navigation.state === "loading") {
    return <h1>Loading keys ...</h1>;
  }

  const handleOpen = (key: ApiKey) => {
    setCurrentKey(key);
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const columns: GridColDef[] = [
    { field: 'token', width: 350,renderHeader: () => (
      <strong>
        {'Key Name'}
      </strong>
    ) },
    { field: 'createdAt', width: 250, renderHeader: () => (
      <strong>
        {'Created Date'}
      </strong>
    ), renderCell: (params: GridRenderCellParams) => {
      const date = new Date(params.value);
      const monthIndex = date.getMonth();
      const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const displayDate = monthNames[monthIndex]+' '+date.getDate()+', '+date.getFullYear();
     return (
     <>      
       {displayDate}
     </>
     );
   } },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        Logger.log('getActions', params)
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleOpen(params.row)}
            color="inherit"
            // component={Link}
            // to={`/counterparties/${params.id}`}
          />,
        ];
      },
      renderHeader: () => (
        <strong>
          {'Action'}
        </strong>
      )
    }
  ];

  const handleDeleteKeyClick = () => {
    Logger.log('delete key', currentKey);
    // delete key
    FetchKeyAPI.setKeyDestroy(currentKey?.id).then((response) => {
      Logger.log('setBatchKeyDestroy response', currentKey?.id, response);

      // update key list
      setCurrentKeys(currentKeys?.filter(key => key?.id?.toString() !== currentKey?.id?.toString()));
      handleClose();
    }).catch((e) => {
      Logger.log(e);
    });
  };

  return (
    <>
      <BasicBreadcrumbs root="Settings" primary="API Keys" url="/settings" />

      <PageHeader title={`Parasail API Keys`} />

      <div className="bg-white" style={{ width: '100%' }}>
        <Stack direction="column">
            <Typography gutterBottom variant="subtitle1" component="div">
            Create an API key and use it to access Parasail APIs, such as 
            <NavLink to={`/batch`} /*state={dedicatedDeployment || {}} */ className="text-blue-500 ml-1 mr-1">
              Batch Processing.
            </NavLink>
          </Typography> 
        </Stack>

        <Stack direction="column" style={{maxWidth: '150px', padding: "15px 0"}}>
          <GeneralCreateKey currentKeys={currentKeys} setCurrentKeys={setCurrentKeys} />
        </Stack>

        <DataGrid 
          rows={currentKeys} 
          columns={columns} 
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{ noRowsOverlay: CustomNoRowsOverlay }}
          autoHeight={true}
          sx={{ 
            '--DataGrid-overlayHeight': '300px',
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            }
          }}
          // checkboxSelection
        />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm delete of key {currentKey?.name}?
          </Typography>
          <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mt: 2 }} style={{padding: "15px", border: "1px solid #DDD", backgroundColor: "#f7f6f9"}}>
            <LoadingButton 
              variant="outlined" 
              onClick={handleClose} 
              loadingIndicator="Canceling..."
              size="small"
            >
              Cancel
            </LoadingButton>  
            <LoadingButton 
              variant="outlined" 
              onClick={handleDeleteKeyClick} 
              // disabled={showDeployButton}
              loadingIndicator="Deleting..."
              // loading={loading}
              size="small"
            >
              Confirm
            </LoadingButton>  
          </Stack>
        </Box>
      </Modal>

    </>
  );
}
export default GeneralKeysView;