import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { Logger } from 'utils/Logger';

const navigation = [
  { name: 'Serverless', href: '/serverless', current: false },
  { name: 'Dedicated', href: '/dedicated', current: false },
  { name: 'Batch', href: '/batch', current: false },
]
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function NavbarActions() {
  const [ currentItemIndex, setCurrentItemIndex ] = React.useState<number>();

  // get the pass thru state
  let location = useLocation();

  // check the location updates
  React.useEffect(() => {
    Logger.log('location', location);
    if(location) {
      const pathName = location.pathname;
      if(currentItemIndex !== undefined && navigation.find(item => item.href !== pathName)) {
        navigation[currentItemIndex].current = false;
      }
    }
  }, [location, currentItemIndex]);

  const handleChange = (item: any, index: number) => {
    // reset last item's current state
    if(currentItemIndex !== undefined) {
      navigation[currentItemIndex].current = false;
    }

    // save new item
    item.current = true;
    setCurrentItemIndex(index);
  };

  return (    
    <div className="hidden sm:ml-6 sm:inline-block">
      <div className="flex space-x-3">
        {navigation.map((item, index) => (
          <NavLink 
            key={item.name}
            to={item.href}
            onClick={() => handleChange(item, index)}
            aria-current={item.current ? 'page' : undefined}  
            style={{textTransform :"none"}}
            className={classNames(
              item.current ? 'text-lg bg-blue-400 text-white' : 'text-lg text-white hover:bg-blue-200 hover:text-gray-600',
              'rounded-md px-3 py-2',
            )}
          >
            {item.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};