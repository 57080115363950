import React, { createContext, useEffect, useState } from "react";
import { Account, User } from "react-app-env";
import { FetchCommonAPI } from "service/FetchService";
import { Logger } from "utils/Logger";

// State of an active user
interface UserState {
  user: User;
  activeAccount: Account;
  isAuthenticated: boolean;
  isAuthorized: boolean;
  isAdmin: boolean;
  hasActiveAccount: boolean;
}

// Props of what User Context provides
interface UserContextProps {
  UserContextService: {
    // userState: UserState;
    setUser: (user: User) => void;
    setActiveAccount: (account: Account) => void;
    setIsAuthenticated: (value: boolean) => void;
    setIsAuthorized: (value: boolean) => void;
    setIsAdmin: (value: boolean) => void;
    setHasActiveAccount: (value: boolean) => void;
    clearUserState: () => void;
    Logout: () => void;
  },
  UserState: UserState,
  isUserContextLoading: boolean,
}

interface UserContextProviderProps {
  children?: React.ReactNode;
}

const UserContext = createContext<UserContextProps | null>(null);

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};

const createPermissions = (account: Account) => {
  // account is needed to be set first to retrieve permissions
  const isAuthorized = (account && account?.accountName?.toLocaleLowerCase() !== '');
  const isAdmin = (isAuthorized && account.accountName?.toLocaleLowerCase() === 'parasail');
  Logger.log('createPermissions', isAdmin, isAuthorized, account);
  return { isAdmin, isAuthorized };
};

export const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const [userState, setUserState] = useState<UserState>({
    user: {} as User,
    activeAccount: {} as Account,
    isAuthenticated: false as boolean,
    isAuthorized: false as boolean,
    isAdmin: false as boolean,
    hasActiveAccount: false as boolean,
  });
  const [isUserContextLoading, setIsUserContextLoading] = useState(true);

  // WARNING: UserContext should not be overriden unless intended.

  // Update User from context
  const setUser = (user: User) => {
    setUserState((prevState) => ({
      ...prevState,
      user: user,
    }));
  };

  // Update isAuthenticated from context
  const setIsAuthenticated = (value: boolean) => {
    setUserState((prevState) => ({
      ...prevState,
      isAuthenticated: value,
    }));
  };

  // Update isAuthorize from context
  const setIsAuthorized = (value: boolean) => {
    setUserState((prevState) => ({
      ...prevState,
      isAuthorized: value,
    }));
  };

  // Update isAdmin from context
  const setIsAdmin = (value: boolean) => {
    setUserState((prevState) => ({
      ...prevState,
      isAdmin: value,
    }));
  };

  // Update hasActiveAccount from context
  const setHasActiveAccount = (value: boolean) => {
    setUserState((prevState) => ({
      ...prevState,
      hasActiveAccount: value,
    }));
  };

  // Update active Account from context
  const setActiveAccount = React.useCallback((account: Account) => {
    setUserState((prevState) => ({
      ...prevState,
      activeAccount: account,
    }));
    // update isAdmin & isAuthenticated & hasActiveAccount
    const permissions =  createPermissions(account);
    setIsAdmin(permissions?.isAdmin as boolean);

    // user is is authorized if they are authenticated and has valid account
    if(permissions?.isAuthorized) {
      setIsAuthorized(true);
    }
    setHasActiveAccount(true);
  }, []);

  const handleDefaultLoaders = React.useCallback(async () => {
    // Fetch User info and save to context
    try {
      // NOTE: This order is required. Need to fetch user profile before the account info.
      const user = await FetchCommonAPI.getUser();
      Logger.log('UserContext getUser user: ', user);
      if(user && Object.keys(user).length !== 0) {
        setUser(user);
        // valid authenthication if user's id exists
        const isAuthenticated = user?.id !== null;
        setIsAuthenticated(isAuthenticated);
      }

      // Fetch Account and save to context
      const account = await FetchCommonAPI.getAccount();
      Logger.log('UserContext getAccount account: ', account);
      if(account && Object.keys(account).length !== 0) {
        setActiveAccount(account);
      }

      // The initial fetching of the user context is now complete.
      setIsUserContextLoading(current => !current);
      Logger.log('UserContext is done.............');
    } catch(e){
      if(e) {
        Logger.log("UserContext done with handleDefaultLoaders error: ", e);
        setIsUserContextLoading(false);
      }
    }
  }, [setActiveAccount]);

  // Fetch the latest user's state and save to userState
  useEffect(() => {
    Logger.log('UserContext is called.............')
    // get user's state from session
    const storedState = sessionStorage.getItem('userState');
    if (storedState) {
      setUserState(JSON.parse(storedState));
    }

    // get required user context (DO NOT REMOVE)
    handleDefaultLoaders();
  }, [handleDefaultLoaders]);

  // save to session storage when user's state updates
  useEffect(() => {
    sessionStorage.setItem('userState', JSON.stringify(userState));
  }, [userState]);

  // Clear the User state from sessiom storage
  const clearUserState = () => {
    // clean up userState
    sessionStorage.removeItem('userState');
    // sessionStorage.clear();// DO NOT USE FOR NOW. Will wipe for all projects.
  }

  // Log out user
  const Logout = () => {
    Logger.log('UserContext logout');
    // Clear session for user state (this context)
    clearUserState();

    FetchCommonAPI.logout().then((response => {
      // redirect user after logout
      if(response.request.responseURL) {
        window.location.replace(`${response.request.responseURL}`);
      } else {
        window.location.replace(window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/");
      }
    }));
  }

  // Only allow access to UserContext from what is exposed through this service
  const UserContextService = { 
    setUser,
    setActiveAccount, 
    setIsAuthenticated,
    setIsAuthorized,
    setIsAdmin,
    setHasActiveAccount,
    clearUserState, 
    Logout 
  }

  return (
    <UserContext.Provider
      value={{ UserContextService, 
              UserState: userState, 
              isUserContextLoading }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
