import { ServerlessDeployment, DedicatedDeployment, Usage} from "react-app-env";
import Grid from '@mui/material/Grid2';
import * as React from 'react';
import Box from '@mui/material/Box';
import { CopyBlock, atomOneLight } from "react-code-blocks";
import ChatPaneStream from "components/views/common/ChatPaneStream";
import Typography from '@mui/material/Typography';
import { NavLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { memo } from "react";
import ChatIcon from '@mui/icons-material/Chat';

interface propType { 
  data?: ServerlessDeployment | DedicatedDeployment, 
  usagesData: Array<Usage>, 
  defaultTab?: string
}

const ModelUsagesPane: React.FC<propType> = ({data, usagesData, defaultTab}) => {
  const [chatData, setChatData] = React.useState<ServerlessDeployment | DedicatedDeployment>();
  const [usageType, setUsageType] = React.useState<string>(defaultTab ? defaultTab : 'chat');
  
  React.useEffect(() => {
    // get the first item for chat data
    if(data) {
      setChatData(data);
    }
  }, [data]);

  const metadataUsage = usagesData?.filter((usage) => usage.type === 'metadata')[0]?.content;
  const curlUsage = usagesData?.filter((usage) => usage.type === 'curl')[0]?.content;
  const pythonUsage = usagesData?.filter((usage) => usage.type === 'python')[0]?.content;
  const javascriptUsage = usagesData?.filter((usage) => usage.type === 'javascript')[0]?.content;

  return (
    <>
      {data !== null ? (
        <>
          <Grid container spacing={0}/* style={{ maxHeight: "500px"}}*/>

            {/* meta data usage */}
            {metadataUsage &&
              <Stack direction="column" justifyContent="start" spacing={1} style={{}}>
                  <Typography gutterBottom variant="subtitle1" component="div">
                      <span className="mr-2">Base Url: </span>
                      <CopyBlock
                        text={JSON.parse(metadataUsage).base_url}
                        language={"bash"}
                        showLineNumbers={false}
                        wrapLongLines={false}
                        theme={atomOneLight}
                        customStyle={{ minWidth: "520px", display: "inline-block"}}
                        codeBlock={true}
                      />
                  </Typography>

                <Typography gutterBottom variant="subtitle1" component="div">
                  <span>Parasail API key: Create one</span>
                  <NavLink to={`/keys`} /*state={dedicatedDeployment || {}} */ >
                    <Button variant="text" size="small" style={{textTransform :"none", minWidth: "unset", fontWeight: 400,
                      fontSize: "1rem", lineHeight: "1.75", letterSpacing: "0.00938em"}}>
                      here
                    </Button>
                  </NavLink>
                  then reuse it for all your batch API calls.
                </Typography>                
              </Stack>
            }

            { (pythonUsage || curlUsage || javascriptUsage) && /* Usage w/ tabs */
            <Grid
              size={{ xs: 12 }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%"}}>
                <div id="usages" className="p-2">
                  <Stack direction="row" spacing={1} className="p-2">
                    { chatData && 
                      <button 
                      className={`flex rounded-lg border px-1.5 py-1 leading-none border-gray-500 text-gray-500 hover:bg-slate-200 ${usageType === "chat" ? 'bg-slate-300 border-black text-black' : ''}`}
                        type="button"
                        onClick={() => setUsageType("chat")}
                      >
                        <ChatIcon color="primary" fontSize="small" className="mr-1" /> Chat
                      </button>
                    }

                    <button 
                      className={`flex rounded-lg border px-1.5 py-1 leading-none border-gray-500 text-gray-500 hover:bg-slate-200 ${usageType === "python" ? 'bg-slate-300 border-black text-black' : ''}`}
                      type="button"
                      onClick={() => setUsageType("python")}
                    >
                      <svg className="mr-1.5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                        <path d="M16.16,31.5a16.4,16.4,0,0,0,3.57-.32c3.17-.57,3.74-1.71,3.74-3.82V24.52H16v-1H26.23a4.73,4.73,0,0,0,4.7-3.74,14.82,14.82,0,0,0,0-7.54c-.57-2.28-1.86-3.82-4-3.82h-2.6v3.41a4.73,4.73,0,0,1-4.63,4.63H12.35a3.72,3.72,0,0,0-3.73,3.73v7.14a4.16,4.16,0,0,0,3.73,3.82A20.49,20.49,0,0,0,16.16,31.5Zm4.06-2.27a1.39,1.39,0,0,1-1.38-1.46,1.37,1.37,0,0,1,1.38-1.38,1.42,1.42,0,0,1,1.38,1.38A1.44,1.44,0,0,1,20.22,29.23Z" fill="#ffd43b"></path>
                        <path d="M15.84.5a16.4,16.4,0,0,0-3.57.32C9.1,1.39,8.53,2.53,8.53,4.64V7.48H16v1H5.77a4.73,4.73,0,0,0-4.7,3.74,14.82,14.82,0,0,0,0,7.54c.57,2.28,1.86,3.82,4,3.82h2.6V20.14a4.73,4.73,0,0,1,4.63-4.63h7.38a3.72,3.72,0,0,0,3.73-3.73V4.64A4.16,4.16,0,0,0,19.65.82,20.49,20.49,0,0,0,15.84.5ZM11.78,2.77a1.39,1.39,0,0,1,1.38,1.46,1.37,1.37,0,0,1-1.38,1.38A1.42,1.42,0,0,1,10.4,4.23,1.44,1.44,0,0,1,11.78,2.77Z" fill="#5a9fd4"></path>
                      </svg> Python
                    </button>

                    { javascriptUsage &&
                      <button 
                      className={`flex rounded-lg border px-1.5 py-1 leading-none border-gray-500 text-gray-500 hover:bg-slate-200 ${usageType === "js" ? 'bg-slate-300 border-black text-black' : ''}`} 
                        type="button"
                        onClick={() => setUsageType("js")}
                      >
                        <svg className="mr-1.5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                          <rect width="32" height="32" fill="#f7df1e"></rect>
                          <path d="M21.5,25a3.27,3.27,0,0,0,3,1.83c1.25,0,2-.63,2-1.49,0-1-.81-1.39-2.19-2L23.56,23C21.39,22.1,20,20.94,20,18.49c0-2.25,1.72-4,4.41-4a4.44,4.44,0,0,1,4.27,2.41l-2.34,1.5a2,2,0,0,0-1.93-1.29,1.31,1.31,0,0,0-1.44,1.29c0,.9.56,1.27,1.85,1.83l.75.32c2.55,1.1,4,2.21,4,4.72,0,2.71-2.12,4.19-5,4.19a5.78,5.78,0,0,1-5.48-3.07Zm-10.63.26c.48.84.91,1.55,1.94,1.55s1.61-.39,1.61-1.89V14.69h3V25c0,3.11-1.83,4.53-4.49,4.53a4.66,4.66,0,0,1-4.51-2.75Z"></path>
                        </svg> JavaScript
                      </button>
                    }

                    <button 
                      className={`flex rounded-lg border px-1.5 py-1 leading-none border-gray-500 text-gray-500 hover:bg-slate-200 ${usageType === "curl" ? 'bg-slate-300 border-black text-black' : ''}`} 
                      type="button"
                      onClick={() => setUsageType("curl")}
                    >
                      <svg className="mr-1.5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                        <rect width="32" height="32" rx="4" fill="#1683a5"></rect>
                        <path d="M6.71,14A5,5,0,0,1,8.82,9.29l2.64-2.2c1.67-1.37,2.52-1.41,4.6-1.41H21.7c1.19,0,2.45.27,2.45,1.79s-1.4,1.78-2.45,1.78H15.44a3.31,3.31,0,0,0-2,.89L11.24,12c-.55.44-1,.81-1,1.52v4.41c0,.7.41,1.07,1,1.52l2.16,1.82a3.34,3.34,0,0,0,2,.89H21.7c1.05,0,2.45.23,2.45,1.78s-1.26,1.78-2.45,1.78H16.06c-2.08,0-2.94,0-4.6-1.4L8.82,22.09A5.05,5.05,0,0,1,6.71,17.4Z" fill="#fff"></path>
                      </svg> cURL
                    </button>
                  </Stack>
                  <Stack className="border" direction="row" spacing={1} display="block">
                    { chatData && usageType === "chat" &&
                      <ChatPaneStream data={chatData} />
                    }

                    { usageType === "python" &&
                      <CopyBlock
                        text={pythonUsage}
                        language={"python"}
                        showLineNumbers={true}
                        wrapLongLines={true}
                        theme={atomOneLight}
                      />
                    }

                    { javascriptUsage && usageType === "js" &&
                      <CopyBlock
                        text={javascriptUsage}
                        language={"javascript"}
                        showLineNumbers={true}
                        wrapLongLines={true}
                        theme={atomOneLight}
                      />
                    }

                    { usageType === "curl" &&
                      <CopyBlock
                        text={curlUsage}
                        language={"curl"}
                        showLineNumbers={true}
                        wrapLongLines={true}
                        theme={atomOneLight}
                      />
                    }
                  </Stack>
                </div>
              </Box>
            </Grid>
            }
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

// Only rerender if props changes
export default memo(ModelUsagesPane);
