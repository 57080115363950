// ****************************************************************************
// Logger
// Example usage: 
// Logger.log('prefersDarkMode now!!!', prefersDarkMode);
// Logger.error('prefersDarkMode now!!!', prefersDarkMode);
// ****************************************************************************

// get user's state from session
const storedState = sessionStorage.getItem('userState')?.toString();
const userState = storedState && storedState.length !== 0 ? JSON.parse(storedState) : {};
const isAdmin = userState && userState.length !== 0 ? userState.isAdmin : false;

export const Logger = {
    log: (...args: any) => {
        // check admin logger param override
        const isDebugger = window.location.search.includes('isDebugger');

        if(isAdmin || isDebugger) {
            console.log.apply(null, args);
        }
    },
    error: (...args: any) => {
        // check admin logger param override
        const isDebugger = window.location.search.includes('isDebugger');
                
        if(isAdmin || isDebugger) {
            console.error.apply(null, args);
        }
    }
  }