import React from 'react';
import 'styles/common.css';

export default function ResourceInfo() {
  return (
    <div>
        <footer className="p-4 sm:p-6 bg-slate-200" 
        style={{ 
            marginTop: "40px"
        }}
        >
            <div className="md:flex md:justify-between">
                <div className="mb-3 md:mb-0">
                    <a href="https://parasail.io" className="flex items-center">
                        {/* <img src="/parasail.svg" className="mr-3 h-14" alt="Parasail Logo" style={{backgroundColor: "#3477EC", borderRadius: "0 12px 0 12px"}} /> */}
                        <div className="company-text--small mr-3 h-14">
                            <h2>PARASAIL</h2>
                        </div>
                        {/* <span className="self-center text-2xl font-semibold whitespace-nowrap">Parasail</span> */}
                    </a>

                    {/* <div className="flex h-5/6 justify-center items-center">
                        <div
                            className="flex flex-wrap items-center w-full max-w-5xl p-5 mx-auto text-left border border-gray-200 rounded lg:flex-nowrap md:p-2">
                            <div className="flex-1 w-full mb-5 md:mb-0 md:pr-5 lg:pr-10 md:w-1/2">
                                <h3 className="mb-2 text-xl font-bold text-gray-700">Subscribe</h3>
                                <p className="text-gray-500">Provide your email to get email notification when we launch
                                </p>
                            </div>
                            <div className="w-full px-1 flex-0 md:w-auto lg:w-1/2">
                                <form>
                                    <input type="hidden" name="tags" value="earlyaccess" />
                                    <div className="flex flex-col sm:flex-row">
                                    <input type="email" id="email" name="email" placeholder="Enter your email address" className="flex-1 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md sm:mr-5 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300" />
                                    <button type="submit" className="w-full px-6 py-4 mt-5 text-white text-lg bg-blue-600 rounded-md sm:mt-0 sm:w-auto whitespace-nowrap">
                                        Sign Up
                                    </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> */}
                </div>
                
                <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                    <div>
                        <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase">Contact</h2>
                        <ul className="text-gray-600">
                            <li className="mb-2">
                                <a href="https://parasail.io/" target="_blank" rel="noreferrer" className="hover:underline">About Us</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://sw5p1mpr8mp.typeform.com/to/gnvTRnW6" target="_blank" rel="noreferrer" className="hover:underline">Sales</a>
                            </li>
                            <li className="mb-2">
                                {/* <Feedback /> */}
                                <a href="https://sw5p1mpr8mp.typeform.com/to/gnvTRnW6" target="_blank" rel="noreferrer" className="hover:underline">Feedback</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase">Follow us</h2>
                        <ul className="text-gray-600">
                            <li className="mb-2">
                                <a href="https://www.linkedin.com/company/parasail-io/" target="_blank" rel="noreferrer" className="hover:underline">Linkedin</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://x.com/parasail_io" target="_blank" rel="noreferrer" className="hover:underline">X.com</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase">Legal</h2>
                        <ul className="text-gray-600">
                            <li className="mb-2">
                                <a href="/assets/privacy-policy.html" target="_blank" rel="noreferrer" className="hover:underline ">Privacy Policy</a>
                            </li>
                            {/* <li className="mb-2">
                                Terms &amp; Conditions
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            
            {/* <hr className="my-2 border-gray-200 sm:mx-auto  lg:my-2" /> */}
            {/* <div className="sm:flex sm:items-center sm:justify-between">
                <span className="text-sm text-gray-500 sm:text-center">© 2024 <a href="https://parasail.io/" className="hover:underline">Parasail</a>. All Rights Reserved.
                </span>
                <div className="flex mt-2 space-x-6 sm:justify-center sm:mt-0">
                    <a href="https://parasail.io/" className="text-gray-500 hover:text-gray-900 ">
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" /></svg>
                    </a>
                    <a href="https://parasail.io/" className="text-gray-500 hover:text-gray-900">
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                    </a>
                    <a href="https://parasail.io/" className="text-gray-500 hover:text-gray-900">
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" /></svg>
                    </a>
                </div>
            </div> */}
        </footer>
    </div>
  )
}
