import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

// Parasail imports
import PageHeader from "components/PageHeader"

const HomeAuthenticatedOnlyView = () => {
  return (
    <>
      <PageHeader title='Welcome to Parasail!' />

      <Stack direction="column" alignItems="center" justifyContent="center" height="80vh">
        <Typography gutterBottom variant="subtitle1" component="div" className="bg-gray-200 p-3" style={{textAlign: "center"}}>
            You're not currently part of any organization. <br/>
            Please click the invite link you received previously.<br/>
            Don't have an invite link? <br />
            <Link className="pl-1 pr-1" target="_blank" variant="body1" href="mailto:hello@parasail.io" underline="always" sx={{'&:hover': {cursor: 'pointer'}}}>
            Contact us 
            </Link>
            to get access.
        </Typography>  
      </Stack>
    </>
  );
};

export default HomeAuthenticatedOnlyView;