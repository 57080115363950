import * as React from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowRight from '@mui/icons-material/ArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Home from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import People from '@mui/icons-material/People';
import Storage from '@mui/icons-material/Storage';
import SwitchAccount from '@mui/icons-material/SwitchAccount';
import Lock from '@mui/icons-material/Lock';
import Public from '@mui/icons-material/Public'
;

const asideItems = [
  { icon: <SwitchAccount />, label: 'Account', href: '/account'},
  { icon: <Lock />, label: 'Credentials', href: '/credentials'},
  { icon: <Storage />, label: 'Clusters', href: '/clusters' },
  { icon: <Public />, label: 'Operations', href: '/operations' },
  { icon: <People />, label: 'Users', href: '/users'},
];

const FireNav = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

export default function Aside() {
  const [open, setOpen] = React.useState(true);
  return (
    <aside id="aside" className="col-span-12 md:col-span-2">
        <Box>
            <ThemeProvider
                theme={createTheme({
                components: {
                    MuiListItemButton: {
                        defaultProps: {
                            disableTouchRipple: true,
                        },
                    },
                },
                palette: {
                    mode: 'dark',
                    primary: { main: '#FFF' },
                    background: { paper: '#3477EC' }
                },
                })}
            >
                <Paper elevation={0} sx={{borderRadius: 0}}>
                <FireNav component="nav" disablePadding> 
                    <ListItemButton component="a" href="#customized-list" sx={{ height: { xs: '65' } }}>
                        <img src="/parasail.svg" alt="parasail logo" style={{ height: "65px"}}/>
                    {/* <ListItemIcon sx={{ fontSize: 20 }}>🔥</ListItemIcon> */}
                    {/* <ListItemText
                        sx={{ my: 0 }}
                        primary="Firebash"
                        primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: 'medium',
                        letterSpacing: 0,
                        }}
                    /> */}
                    </ListItemButton>
                    <Divider />
                    <ListItem component="div" disablePadding>
                    <ListItemButton sx={{ height: 56 }} href="/">
                        <ListItemIcon>
                            <Home color="primary" />
                        </ListItemIcon>
                        <ListItemText
                            primary="Dashboard"
                            primaryTypographyProps={{
                                color: 'primary',
                                fontWeight: 'medium',
                                variant: 'body2',
                            }}
                        />
                    </ListItemButton>
                    <Tooltip title="Project Settings">
                        <IconButton
                            size="large"
                            sx={{
                                '& svg': {
                                color: 'rgba(255,255,255,0.8)',
                                transition: '0.2s',
                                transform: 'translateX(0) rotate(0)',
                                },
                                '&:hover, &:focus': {
                                bgcolor: 'unset',
                                '& svg:first-of-type': {
                                    transform: 'translateX(-4px) rotate(-20deg)',
                                },
                                '& svg:last-of-type': {
                                    right: 0,
                                    opacity: 1,
                                },
                                },
                                '&::after': {
                                content: '""',
                                position: 'absolute',
                                height: '80%',
                                display: 'block',
                                left: 0,
                                width: '1px',
                                bgcolor: 'divider',
                                },
                            }}
                        >
                        <Settings />
                        <ArrowRight sx={{ position: 'absolute', right: 4, opacity: 0 }} />
                        </IconButton>
                    </Tooltip>
                    </ListItem>
                    <Divider />
                    <Box
                        sx={{
                            bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
                            pb: open ? 2 : 0,
                        }}
                    >
                    <ListItemButton
                        alignItems="flex-start"
                        onClick={() => setOpen(!open)}
                        sx={{
                        px: 3,
                        pt: 2.5,
                        pb: open ? 0 : 2.5,
                        '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
                        }}
                    >
                        <ListItemText
                        primary="Manage"
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            mb: '2px',
                        }}
                        secondary="Account, Credentials, Clusters, Operations, Users"
                        secondaryTypographyProps={{
                            noWrap: true,
                            fontSize: 12,
                            lineHeight: '16px',
                            color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                        }}
                        sx={{ my: 0 }}
                        />
                        <KeyboardArrowDown
                        sx={{
                            mr: -1,
                            opacity: 0,
                            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                            transition: '0.2s',
                        }}
                        />
                    </ListItemButton>
                        {open && asideItems?.map((item) => (
                            <ListItemButton
                                key={item.label}
                                sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}
                                href={item.href ? item.href : ''}
                            >
                                <ListItemIcon sx={{ color: 'inherit' }}>
                                {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.label}
                                    primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                                />
                            </ListItemButton>
                        ))}
                    </Box>
                </FireNav>
                </Paper>
            </ThemeProvider>
        </Box>
    </aside>
  );
}
