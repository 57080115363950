import React, { useRef, useState } from 'react';
import ButtonMui from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Tooltip from '@mui/material/Tooltip';

// file upload
// import { Toast } from 'primereact/toast';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent, ItemTemplateOptions,} from 'primereact/fileupload';
// import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
// import { Tooltip } from 'primereact/tooltip';
// import { Tag } from 'primereact/tag';

// theme
import 'primereact/resources/themes/mdc-light-indigo/theme.css';
import 'primeicons/primeicons.css';

// Parasail imports
import { Logger } from "utils/Logger";
import { 
    FetchBatchAPI 
  } from "service/FetchService";


const style = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 620,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 12,
    p: 4,
};

interface FileLimit {
    "maxInputSize": number;
    "maxInputLineCount": number;
    "maxMetadataCount": number;
    "maxMetadataKeyLength": number;
    "maxMetadataValueLength": number;
}

interface Props {
    callRefetchBatchData: () => void
}
interface Metadata {
    id: string | undefined; key: string; value: string;
}

const BatchCreateModal : React.FC<Props> = ({callRefetchBatchData}) => {
    const [openBatchModal, setOpenBatchModal] = useState(false);
    const [showMetadataWarning, setShowMetadataWarning] = useState(false);
    const [rows, setRows] = useState(Array<Metadata>);
    // const [disableAdd, setDisableAdd] = useState(false);
    // const [isMaxInputCount, setIsMaxInputCount] = useState(false);
    const [file, setFile] = useState<File>(Object);
    const [fileUploadInfo, setFileUploadInfo] = useState<{fileId: string, uploadUrl: string, uploadHeaders: Record<string, string>}>(Object)
    const [fileLimits, setFileLimits] = useState<FileLimit>(Object);
    const [isShowLoadingBatchIcon, setIsShowLoadingBatchIcon] = useState(false);
    const [isDisableCreateBatchBtn, setIsDisableCreateBatchBtn] = useState(true);
    
    // const toast = useRef<Toast>(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef<FileUpload>(null);

    // React.useEffect(() => {
    //     const limit = fileLimits.maxMetadataCount || 32;
    //     if(rows && rows.length === limit) {
    //         //disable button
    //         setDisableAdd(true);
    //     } else {
    //         setDisableAdd(false);
    //     }
    // }, [rows, fileLimits.maxMetadataCount]);


    const onTemplateSelect = React.useCallback((e: FileUploadSelectEvent) => {
        let _totalSize = totalSize;
        let files = e.files;

        for (let i = 0; i < files.length; i++) {
            _totalSize += files[i].size || 0;
        }
        Logger.log('onTemplateSelect', files);

        const file = files[0];

        // save file
        setFile(file);
        setTotalSize(_totalSize);

        // fetch S3 upload url
        FetchBatchAPI.setBatchUpload(file.name).then((response) => {
            Logger.log('setBatchUpload response', response);

            // save the fileId and uploadUrl
            setFileUploadInfo(response);
        }).catch((e) => {
            Logger.log(e);
        });
    }, [totalSize]);

    const onTemplateUpload = (e: FileUploadUploadEvent) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        Logger.log('onTemplateSelect', e.files)
        setTotalSize(_totalSize);

        // call toast and close modal
        // toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        setFileUploadInfo({fileId: '', uploadUrl: '', uploadHeaders: {}});
        setIsDisableCreateBatchBtn(true);
        setRows([]);
        setShowMetadataWarning(false);
        
        callback();
    };

    const onTemplateClear = () => {
        Logger.log('onTemplateClear reset');
        setTotalSize(0);
        setFileUploadInfo({fileId: '', uploadUrl: '', uploadHeaders: {}});
        setIsDisableCreateBatchBtn(true);
        setRows([]);
        setShowMetadataWarning(false);
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton/*, chooseButton, uploadButton, cancelButton*/ } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        const fileMaxInputSize = fileLimits.maxInputSize;
        const formatedFileMaxInputSize = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(fileMaxInputSize) : '0 B';

        let isFileSizeLimit = false;
        if (value > fileMaxInputSize) {
            isFileSizeLimit = true;
        }

        return (
            <>
                <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                    {chooseButton}
                    {/* <span style={{color: "green"}}>{uploadButton}</span> */}
                    {/* <span style={{color: "red"}}>{cancelButton}</span> */}

                    <div className="flex align-items-center gap-3 ml-auto">
                        <span>{formatedValue} / {formatedFileMaxInputSize} (max {fileLimits.maxInputLineCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} lines)</span>
                        {/* <ProgressBar value={value} showValue={false} style={{ alignSelf: 'center', width: '10rem', height: '12px' }}></ProgressBar> */}
                    </div>
                    
                </div>
                { isFileSizeLimit &&
                    <div className="mb-3">
                        <Alert severity="error" className="mt-1">File size is over the max limit. Limit is {formatedFileMaxInputSize}.</Alert>
                    </div>
                }
                 {/* { isMaxInputCount &&
                    <div className="mb-3">
                        <Alert severity="error" className="mt-1">File max input count is over max limit. Limit is {fileLimits.maxInputLineCount}.</Alert>
                    </div>
                } */}
            </>
        );
    };

    const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as File;

        setIsDisableCreateBatchBtn(false);

        return (
            <div className="bg-slate-100 flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    {/* <img alt={file.name} role="presentation" src={(file as any).objectURL} width={100} /> */}
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        {/* <small>{new Date().toLocaleDateString()}</small> */}
                    </span>
                </div>
                {/* <Tag value={props.formatSize} severity="warning" className="px-3 py-2" /> */}
                <Button type="button" icon="pi pi-times" style={{color: "red"}} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <>
                <div className="bg-slate-100 flex align-items-center flex-row" style={{minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {/* <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i> */}
                    <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="p-3">
                        Drag and Drop File Here
                    </span>
                </div>
            </>
        );
    };

    const handleCloseCreateBatchModal = React.useCallback(() => {
        setOpenBatchModal(false);
        onTemplateClear();// clear all file modal
    }, []);
    const handleOpenCreateBatchModal = React.useCallback(() => {
        Logger.log('handleOpenCreateBatchModal');
        FetchBatchAPI.getBatchLimits().then((response) => {
            Logger.log('getBatchLimits response', response);

            // save the limits
            setFileLimits(response);

            // opens batch create modal
            setOpenBatchModal(true);
        }).catch((e) => {
            Logger.log(e);
        });
    }, []);

    // handle the upload to storage and create batch
    const handleFileUpload = () => {
        Logger.log('fileUploadInfo', fileUploadInfo);

        setIsShowLoadingBatchIcon(true);

        // upload the file to storage
        FetchBatchAPI.updateBatchUpload(fileUploadInfo.uploadUrl, fileUploadInfo.uploadHeaders, file).then((response) => {
            Logger.log('updateBatchUpload response', response);

            // filter empty meta and format the metadata for fetching call
            const metadata =  rows.filter(item => item.key !== '' && item.value !== '').reduce((acc, item) => {
                const newItem = {} as any;
                newItem[item.key] = item.value;
                const newData = {...acc, ...newItem};
                return newData;
            }, {});

            // call create batch after successful upload of file
            FetchBatchAPI.createBatch(fileUploadInfo.fileId, metadata).then((response) => {
                Logger.log('createBatch successful', response);
                callRefetchBatchData();//update the batch list
                setIsShowLoadingBatchIcon(false);
                fileUploadRef.current?.clear();
                // close modal
                handleCloseCreateBatchModal();

                if (response.deduped) {
                    // TODO: Convert this log message into a banner over the batch table
                    Logger.log("Found a previously-submitted batch identical input: "
                        + response.externalId + "." +
                        " If you'd like to re-run it anyway, change the input filename or a metadata entry.");
                }
                // TODO: Scroll the batch table to this batch and highlight it.
            }).catch((e) => {
                Logger.log(e);
            });
        }).catch((e) => {
            Logger.log(e);
        });
    }

    const handleAddKey = React.useCallback(() => {
        setShowMetadataWarning(false);
        setRows((currRows) => {
            const idCount = currRows.length+1;
            return [...currRows, {id: idCount.toString(), key: '', value: ''}];
        });
    }, []);

    const handleDeleteRowsItem = React.useCallback((id: undefined | string) => {
        setShowMetadataWarning(false);
        setRows((currRows) => {
            const newRows = currRows.filter(item => item.id !== id);
            return [...newRows];
        });
    }, []);

    const handleRowEditCommit = React.useCallback((type: string, row: any, updatedValue: any, currentRows: Array<Metadata>, prevId?: string) => {
        // if(updatedValue.trim().length === 0)
        //     return;

        //check key and value length
        if(type === 'key') {
           // check for duplicate key
            const hasDuplicate = currentRows.length > 1 && currentRows.filter(item => item.key === updatedValue).length !== 0;
            if(hasDuplicate) {
                // alert user
                setShowMetadataWarning(true);
                //reset fields
                row.key = '';
                row.value = '';
                return;
            } else {
                setShowMetadataWarning(false);
                row.key = updatedValue.trim();
            }

            if (row.key.length > fileLimits.maxMetadataKeyLength) {
                row.key = (updatedValue.key as string).slice(0,  row.key.length - ( row.key.length - fileLimits.maxMetadataKeyLength));
            }
        } else if (type === 'value'){
            row.value = updatedValue.trim();
            if (row.value.length > fileLimits.maxMetadataValueLength) {
                row.value = (updatedValue.value as string).slice(0, row.value.length - ( row.value.length - fileLimits.maxMetadataValueLength));
            }
        }

        // save new values to rows
        setRows((currRows) => {
            const newRows = currRows.map(item => item.id === row.id ? row : item);
            return newRows;
        });
    },
    [fileLimits.maxMetadataKeyLength, fileLimits.maxMetadataValueLength]);

    const chooseOptions = { icon: 'pi pi-fw pi-images', label: 'Choose file', iconOnly: false, className: 'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorPrimary css-1gj9aim-MuiButtonBase-root-MuiButton-root' };
    return (
        <>
            <ButtonMui variant="contained" size="small" onClick={handleOpenCreateBatchModal} style={{textTransform :"none"}}>
              Create batch
            </ButtonMui>

            <Modal
                open={openBatchModal}
                onClose={handleCloseCreateBatchModal}
                aria-labelledby="batch-modal-title"
                aria-describedby="batch-modal-description"
                className='bg-white'
            >
                    <Box sx={style}>
                        <Stack direction="row" justifyContent="space-between" spacing={0} sx={{ mt: 2 }}>
                            <Typography id="batch-modal-title" variant="h6" component="h6">
                                Create Batch
                            </Typography>
                            <IconButton 
                                aria-label="delete" 
                                onClick={handleCloseCreateBatchModal}
                                sx={(theme) => ({
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: theme.palette.grey[500],
                                })}
                            >
                                <Close />
                            </IconButton>
                        </Stack>

                        {/* <div className="mt-2 mb-2">Please save this key for future use. You will not see this key again.</div> */}
                        <Grid style={{ paddingLeft: "0", alignContent: "center" }}>
                            <Typography id="batch-modal-title" variant="caption">
                                Select a <Link target="_blank" href="https://platform.openai.com/docs/api-reference/batch/request-input" variant="body2">
                                            JSONL-formatted
                                        </Link> batch input file.
                            </Typography>
                            {/* <Toast ref={toast}></Toast>

                            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" /> */}

                            <FileUpload 
                                ref={fileUploadRef} name={file.name} /*url={fileUploadInfo.uploadUrl}*/  multiple={false} accept=".jsonl" maxFileSize={fileLimits.maxInputSize}
                                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                customUpload={true} uploadHandler={handleFileUpload}  /*disabled={isUploadFileDisabled}*/
                                chooseOptions={chooseOptions} /*uploadOptions={uploadOptions} cancelOptions={cancelOptions}*/ />
                        </Grid>

                        {/* Metadata */}
                        <Grid>
                            <Stack direction="row">
                                <Typography variant="body2">Use metadata to describe your batch. (optional)</Typography>
                            </Stack>
                            <Stack alignItems="left" direction="column" style={{maxHeight: "380px",  overflow: "hidden", overflowY: "auto"}}>
                                {rows && rows.length < 9 && rows.map((item, index) => <Stack alignItems="center" direction="row" spacing={1} sx={{ mt: 2 }} key={item.id}>
                                    <input 
                                        // onChange={handleModelNameChange} 
                                        type="text" 
                                        id={item.id} 
                                        // name={item.key}
                                        value={item.key}
                                        className=" border-gray-300 border-gray-300 text-gray-500  placeholder:text-gray-500 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500" 
                                        placeholder="label"
                                        style={{ height: "30px", width: "120px" }}
                                        maxLength={fileLimits.maxMetadataKeyLength}
                                        onChange={(event) => handleRowEditCommit("key", item, event.target.value, rows, item.id)}
                                    />
                                    <span className="text-gray-500">:</span>
                                    <input 
                                        // onChange={handleModelNameChange} 
                                        type="text" 
                                        // id={item.key}
                                        // name={item.key}
                                        value={item.value}
                                        className=" border-gray-300 border-gray-300 text-gray-500  placeholder:text-gray-500 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500" 
                                        placeholder="value"
                                        style={{ height: "30px", width: "350px" }}
                                        maxLength={fileLimits.maxMetadataValueLength}
                                        onChange={(event) => handleRowEditCommit("value", item, event.target.value, rows)}
                                    />
                                    <DeleteIcon onClick={() => handleDeleteRowsItem(item.id)} />
                                    {index+1 === rows.length && rows.length < 8 && 
                                        <Tooltip title="add metadata item">
                                            <AddCircleOutlineIcon onClick={handleAddKey} />
                                        </Tooltip>
                                    }
                                </Stack>
                                )}
                                {rows.length === 0 && <>
                                    <Stack direction="row">
                                        <Typography variant="body2">Click to add new metadata item
                                            <Tooltip title="add metadata item">
                                                <AddCircleOutlineIcon className="ml-1" onClick={handleAddKey} />
                                            </Tooltip>
                                        </Typography>
                                    </Stack>
                                </>}
                                { showMetadataWarning &&
                                 <Alert className="mt-2" severity="warning">Labels must be unique.</Alert>
                                }
                            </Stack>
                        </Grid>

                        {/* Create batch button */}
                        <Grid>
                            <Stack direction="row"  justifyContent="end" spacing={0} sx={{ mt: 2 }}>
                                {!isShowLoadingBatchIcon ? 
                                <>
                                    <ButtonMui variant="contained" size="small" onClick={() => fileUploadRef.current?.upload()} disabled={isDisableCreateBatchBtn}>
                                        Create Batch
                                    </ButtonMui>
                                </>
                                :
                                <Backdrop
                                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                                    open={isShowLoadingBatchIcon}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                }
                            </Stack>
                        </Grid>
                    </Box>
            </Modal>
        </>
    )
}
export default BatchCreateModal;