import * as React from 'react';

import { Chip } from '@mui/material';

interface IconProps {
  status: string | undefined;
}

const ModelStatusIcon: React.FC<IconProps> = ({ status }): React.JSX.Element => {
    if (status === "OFFLINE") {
      return (
        <Chip
          label=""
          color="primary"
          size="small"
          style={{
            height: "15px",
            width: "15px",
            color: "white",
            backgroundColor: "#e5e7eb", //grey
            border: "1px solid #94a3b8",
          }}
        />
      );
    } else if (status === "STARTING") {
      return (
        <Chip
          label=""
          color="primary"
          size="small"
          style={{
            height: "15px",
            width: "15px",
            color: "white",
            backgroundColor: "#fef08a", // yellow
            border: "1px solid #94a3b8",
          }}
        />
      );
    } else if (status === "STOPPING") {
      return (
        <Chip
          label=""
          color="primary"
          size="small"
          style={{
            height: "15px",
            width: "15px",
            color: "white",
            backgroundColor: "#fef08a", //yellow
            border: "1px solid #94a3b8",
          }}
        />
      );
    } else if (status === "ONLINE") {
      return (
        <Chip
          label=""
          color="primary"
          size="small"
          style={{
            height: "15px",
            width: "15px",
            color: "white",
            backgroundColor: "#22c55e", //green
            border: "1px solid #94a3b8",
          }}
        />
      );
    } else {
      return (
        <Chip
          label=""
          color="primary"
          size="small"
          style={{
            height: "15px",
            width: "15px",
            color: "white",
            backgroundColor: "#ef4444", //red
            border: "1px solid #94a3b8",
          }}
        />
      );
    }
  };

export default ModelStatusIcon;