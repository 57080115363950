import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate, useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import TextField from '@mui/material/TextField';

// Parasail imports
import {  FetchCommonAPI } from "service/FetchService";
import { Invite, Account } from "react-app-env";
import { Logger } from "utils/Logger";

// Parasail imports
import PageHeader from "components/PageHeader";
import { useUserContext } from "components/providers/UserContext";

const SignupView = () => {
  const [displayName, setDisplayName] = React.useState("");
  const [inviteCode, setInviteCode] = React.useState("");
  const [isErrorAlert, setIsErrorAlert] = React.useState(false);

  const navigate = useNavigate();
  const { UserState, UserContextService } = useUserContext();

  // get any params from url
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const inviteCode = searchParams.size !== 0 && searchParams.get("inviteCode");

    if(inviteCode && inviteCode.length !== 0) {
      setInviteCode(inviteCode);
    }
  }, [searchParams]);

  const handleApplyDisplayName = () => {
    Logger.log('handleApplyDisplayName', displayName);
    if(inviteCode !== '' && displayName !== '') {

      setIsErrorAlert(false);

      const accountTemplate: Account = {
        accountName: displayName,
        displayName
      };

      const invite: Invite = {
        inviteCode,
        accountTemplate
      };

      // Set Invite Code
      FetchCommonAPI.setInvite(invite).then((response) => {
        Logger.log('setInvite code response', response);
        if(response) {
          Logger.log('setInvite code succeeded. Routing back to Home to show marketing content.', response, UserState);
          if(UserState.isAuthenticated && !UserState.isAuthorized) {
            // update user context
            UserContextService.setIsAuthorized(true);
            UserContextService.setActiveAccount(response);
          }
          // route user with invite success flag
          navigate('/home?inviteSuccess=true');
        }
      }).catch((e) => {
        Logger.log(e);
        setIsErrorAlert(true);
        setDisplayName('');
      });
    }
  }

  return (
    <>
      <PageHeader title="Parasail Sign Up" customStyles={{ color: '#1976d2', fontWeight: "500" }} />

      <div style={{minHeight: "80vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Box /*className="w-2/6"*/ style={{maxWidth: "650px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Grid container spacing={6} className="p-6 bg-white"  sx={{ border: "1px solid #ddd"}}>
            <Grid size={{ xs: 12 }} style={{paddingTop: "0", paddingLeft: "0"}}>
              <span className='text-gray-800 font-bold' style={{fontSize: "2em"}}>Enter your organization name</span>
              <Alert className="mt-3" style={{fontSize: "1em"}} icon={<CheckIcon fontSize="inherit" />} severity="success">
                You have a valid invite!
              </Alert>
              {isErrorAlert &&
                <Alert className="mt-3" severity="error">
                  Sorry. Something went wrong. Please retry.
                </Alert>
              }
            </Grid>
            <Grid size={{ xs: 12 }}>
              <form className="w-12/12 xl:w-full xl:mx-0">
                <Stack spacing={2} direction="row" style={{display: "flex", justifyContent: "space-between"}}>
                  <TextField 
                    label=""
                    placeholder="Enter your organization name here"
                    color="primary" 
                    onChange={(e) => {
                      setDisplayName(e.target.value);
                    }}
                    id="displayName"
                    name="displayName"
                    className="w-5/6 mr-2"
                    onInput = {(e) =>{
                      (e.target as HTMLTextAreaElement).value = (e.target as HTMLTextAreaElement).value.slice(0,255)
                    }}
                    focused 
                  />
                  <Button
                    variant="contained"
                    onClick={handleApplyDisplayName}
                    style={{textTransform :"none"}}
                    disabled={displayName === ''}
                  >
                    Continue
                  </Button>
                </Stack>
              </form>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default SignupView;