
import * as React from 'react';
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NavLink } from "react-router-dom";
import Link from '@mui/material/Link';

// Parasail imports
import { useUserContext } from "components/providers/UserContext";
import { List, ListItem } from '@mui/material';
import { FetchCommonAPI } from 'service/FetchService';
import { Account } from "react-app-env";
import { Logger } from "utils/Logger";

const SettingsView = () => {
  const [accounts, setAccounts] = React.useState(Array<Account>);

  const { UserContextService, UserState, isUserContextLoading } = useUserContext();

  const fetchAccounts = React.useCallback(() => {
    FetchCommonAPI.getAccounts().then((response) => {
      if (response && Object.keys(response).length !== 0) {
        setAccounts(response);
      }
    }).catch((e) => {
      Logger.log('Navbar getAccounts error: ', e.code);
    });
  }, []);

  // Fetch Accounts
  React.useEffect(() => {
    // Get accounts
    fetchAccounts();
  }, [fetchAccounts]);

  // prevent rendering actual content till the User Context is ready
  if (isUserContextLoading) {
    return <div></div>;
  }

  return (
    <>
      <BasicBreadcrumbs primary="Settings" />

      <PageHeader title="Settings" />
      <div
        className='bg-white'
        style={{
          minHeight: "100%",
          display: "block",
          justifyContent: "center",
        }}
      >
        <p
          className="mb-3 text-gray-800  font-bold"
          style={{ fontSize: "1em" }}
        >
          {UserState.user.username && `Hello, ${UserState.user.username}`}
        </p>
        <Divider/>

        <Stack direction="column" style={{ padding: "15px 0"}}>
          <Typography variant="h6" component="h6">
            Profile
          </Typography>
          <p className="text-gray-800  mt-1">
            Your email:
            <strong className="ml-1">{UserState.user.email}</strong>
          </p>
        </Stack>

        <Divider/>

        <Stack direction="column" style={{ padding: "15px 0"}}>
          <Typography variant="h6" component="h6">
           Organization
          </Typography>
          <p className="text-gray-800  mt-1">
            Your active organization is
            <strong className="ml-1">{UserState.activeAccount?.displayName}</strong>
          </p>

          {accounts?.length > 0 && <>
              <p className="text-gray-800  mt-3">
                You are a member of the following organizations. Click to switch between them.
              </p>

              <List>
                  <ListItem>
                    <Stack direction="row" display="inline-block">
                      {accounts?.map((a, index) => (
                        <Chip key={`chip-${index}`} style={{margin: "5px"}} color="primary" label={a.displayName} onClick={() => FetchCommonAPI.setAccount(a.accountName || '').then(() => UserContextService.setActiveAccount(a))} />
                      ))}
                    </Stack>
                  </ListItem>
              </List>
            </>
        }
        </Stack>

        <Divider/>

        <Stack direction="column" style={{ padding: "15px 0"}}>
          <Typography variant="h6" component="h6">
           API keys
          </Typography>
          <Typography gutterBottom variant="subtitle1" component="div">
            Create an API key 
            <NavLink to={`/keys`} /*state={dedicatedDeployment || {}} */ className="text-blue-500 ml-1 mr-1">
                here
            </NavLink>
            and use it to access Parasail APIs, such as Batch Processing.
          </Typography>    
        </Stack>

        <Divider/>

        <Stack direction="column" style={{ padding: "15px 0"}}>
          <Typography variant="h6" component="h6">
           Billing
          </Typography>
          <Typography gutterBottom variant="subtitle1" component="div">
              You can manage billing for <strong>{UserState.activeAccount?.displayName}</strong>
            <NavLink to={`/billing`} /*state={dedicatedDeployment || {}} */ className="text-blue-500 ml-1">
                here.
            </NavLink>
          </Typography>    
        </Stack>

        <Divider/>

        <Stack direction="column" style={{ padding: "15px 0"}}>
          <Typography variant="h6" component="h6">
           Invite new member
          </Typography>
          <Typography gutterBottom variant="subtitle1" component="div">
              Go
            <NavLink to={`/invite`} /*state={dedicatedDeployment || {}} */ className="text-blue-500 ml-1 mr-1">
                here
            </NavLink>
             to invite new members.
          </Typography>    
        </Stack>

        <Divider/>

        <Stack direction="column" style={{ padding: "15px 0"}}>
          <Typography variant="h6" component="h6">
           Need help?
          </Typography>
          <Typography gutterBottom variant="subtitle1" component="div">
              Please
              <Link className="pl-1 pr-1" target="_blank" variant="body1" href="mailto:hello@parasail.io" underline="always" sx={{'&:hover': {cursor: 'pointer'}}}>
                contact us
              </Link>
              for more info.
          </Typography>    
        </Stack>
      </div>
    </>
  );
};

export default SettingsView;