import * as React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { useNavigate  } from "react-router-dom";

interface Props { 
    cardTitle: string, 
    cardLink: string,
    cardBtnText?: string,
    CardIcon?: React.JSX.Element,
    CardBody: () => React.JSX.Element 
}

const MiniCard: React.FC<Props> = ({cardTitle, cardLink, cardBtnText, CardIcon, CardBody}) => {
    const navigate = useNavigate();
    const link = cardLink.toString();

    return (
    <Card>
        <CardActionArea sx={{minHeight: "350px"}} onClick={() => navigate(link)}>
            <CardContent>
                {/* {CardIcon} */}
                <Typography gutterBottom variant="h4" component="div" sx={{display: "flex", justifyContent: "center"}}>
                    {cardTitle}
                </Typography>
                <CardBody/>
            </CardContent>
        </CardActionArea>
        <CardActions sx={{marginTop: "20px"}} className="bg-slate-200 ">
            <Box>
                <Button onClick={() => navigate(link)} size="small" color="primary" style={{textTransform :"none"}}>
                    Learn more
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box>
                <Button onClick={() => navigate(link)} variant="contained" style={{textTransform :"none"}}>
                    {cardBtnText ? cardBtnText : 'Start'}
                </Button>
            </Box>
        </CardActions>
    </Card>
    );
}

export default MiniCard;