import * as React from 'react';
import { useNavigation } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import useMediaQuery from '@mui/material/useMediaQuery';

// Parasail imports
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import BillingOverview from "components/views/common/BillingOverview";
// import BillingPaymentInfo from "components/views/common/BillingPaymentInfo";

const tabs = [
  {
    label: 'Overview',
    subText: '',
    component: BillingOverview
  },
//   {
//     label: 'Payment info',
//     subText: '',
//     component: BillingPaymentInfo
//   }
];

const BillingView = () => {
  const [tabValue, setTabValue] = React.useState('1');
  
  // const billData = useLoaderData() as any[];
  const navigation = useNavigation();
  const mobile = useMediaQuery('(max-width:400px)');
  const tablet = useMediaQuery('(max-width:1000px)');
  
  if (navigation.state === "loading") {
    return <h1>Loading keys ...</h1>;
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <BasicBreadcrumbs root="Settings" primary="Billing" url="/settings" />

      <PageHeader title="Billing" />

      {/* Billing tabs */}
      <Grid className="bg-white" size={{ xs: 12, md: 12 }} style={{minHeight: mobile || tablet ?  "120vh" : "100vh"}}>
        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  { tabs?.map((tab, index) => {
                    const indexFormatted = index + 1;
                    return (
                      <Tab key={`tab-${indexFormatted.toString()}`} label={tab.label} value={indexFormatted.toString()} sx={{textTransform :"none"}} />
                    ); 
                  })}
                </TabList>
              </Box>
              { tabs?.map((tab, index) => {
                const indexFormatted = index + 1;
                return (
                  <TabPanel key={`tab-${indexFormatted.toString()}`} value={indexFormatted.toString()} sx={{padding: "5px"}}>
                    {tab.component && (<>
                      <tab.component subText={tab.subText} />
                    </>)
                    }
                  </TabPanel>
                ); 
              })}
            </TabContext>
          </Box>
        </Stack>
      </Grid>
      {/* Billing tabs end */}
    </>
  );
}
export default BillingView;