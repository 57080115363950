import { PanelResizeHandle } from "react-resizable-panels";

import styles from "styles/styles.module.css";

export default function ResizeHandle({
  className = "",
  id
}: {
  className?: string;
  id?: string;
}) {
  return (
    <PanelResizeHandle
      className={[styles.ResizeHandleOuter, className].join(" ")}
      id={id}
    >
      <div className={styles.ResizeHandleInner}>
        <svg className={styles.Icon} viewBox="0 0 32 32">
          <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="2" y1="16" x2="30" y2="16"/>
          <polyline fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" points="10,24.5 1.5,16 10,7.5 "/>
          <polyline fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" points="22,7.5 30.5,16 22,24.5 "/>
        </svg>
      </div>
    </PanelResizeHandle>
  );
}