import * as React from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// https://react-tailwindcss-datepicker.vercel.app/theming-options
import Datepicker, { DateRangeType } from "react-tailwindcss-datepicker";
// import { useSearchParams } from "react-router-dom";

// Parasail imports
import BillingOverviewBreakdownServerless from "components/views/common/BillingOverviewBreakdownServerless";
import BillingOverviewBreakdownDedicated from "components/views/common/BillingOverviewBreakdownDedicated";
import {  FetchBillingAPI } from "service/FetchService";
import { BillingServerless, BillingServerlessItems, BillingDedicated, BillingDedicatedItems } from "react-app-env";
import { Logger } from "utils/Logger";

interface Props { 
    subText?: string, 
}
interface IMyProps { 
    data: BillingServerless | BillingDedicated,
    dateRange?: DateRangeType
}
interface BreakdownItem {
 label: string,
 data: BillingServerless | BillingDedicated,
 component: React.FC<IMyProps>
}

const tabs = [
    {
      label: 'Serverless',
      data: {} as BillingServerless,
      component: BillingOverviewBreakdownServerless
    }, 
    {
      label: 'Dedicated', 
      data: {} as BillingDedicated,
      component: BillingOverviewBreakdownDedicated
    }
  ];

  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

const BillingOverview: React.FC<Props> = ({subText}) => {
    // const [endDate, setEndDate] = React.useState<string>('');
    const [totalUsage, setTotalUsage] = React.useState<string>('');
    const [tabValue, setTabValue] = React.useState<string>('1');
    const [breakdownTabs, setBreakdownTabs] = React.useState(tabs as Array<BreakdownItem>);
    // const [billingAllServerlessKeys, setBillingAllServerlessKeys] = React.useState<BillingServerlessItems>(Object);
    // const [billingAllServerlessDeployments, setBillingAllServerlessDeployments] = React.useState<BillingServerlessDeployments>(Object);
    const [dateRange, setDateRange] = React.useState<DateRangeType>({
        startDate: firstDay,
        endDate: lastDay
    });
    // const [isSubscriptionSuccess, setIsSubscriptionSuccess] = React.useState(false);
    // const [isSubscriptionCancelled, setIsSubscriptionCancelled] = React.useState(false);
    // const [isSubscribed, setIsSubscribed] = React.useState(false);

  // get any params from url
  //   const [searchParams] = useSearchParams();
  
    // React.useEffect(() => {
    //     // check if there is a subscription
    //     FetchBillingAPI.getSubscribed().then((response: String) => {
    //         if(response) {
    //             setIsSubscribed(currentIsSubscribed => !currentIsSubscribed);
    //         }
    //     }).catch((e: any) => {
    //         console.log(e);
    //     });
    // }, []);

    // Initially data loading
    React.useEffect(() => {
        const startDate = dateRange.startDate?.toISOString() ? +Date.parse(dateRange.startDate?.toISOString()) :  +firstDay;
        const endDate = dateRange.endDate?.toISOString() ? +Date.parse(dateRange.endDate?.toISOString()) : +lastDay;//Date.now();

        Logger.log('updated start and end dates: ', startDate, endDate);

        // Fetch serverless keys
        FetchBillingAPI.getBillingAllServerlessKeysByTimeRange(startDate, endDate).then((response: BillingServerlessItems) => {
            Logger.log('getBillingAllServerlessKeysByTimeRange response', response);
            // setBillingAllServerlessKeys(response as BillingServerlessItems);
            // update the tab's items (keys)
            const updatedBillingTab = breakdownTabs?.map(tab => {
                if (tab && tab.label === 'Serverless') {
                    (tab.data as BillingServerless).keys = response as BillingServerlessItems;
                }
                return tab;
            });
            setBreakdownTabs(updatedBillingTab);
        }).catch((e: any) => {
            Logger.error("BillingOverview - getBillingAllServerlessKeysByTimeRange: ",e);
        });

        // Fetch serverless deployments
        FetchBillingAPI.getBillingAllServerlessDeploymentsByTimeRange(startDate, endDate).then((response: BillingServerlessItems) => {
            Logger.log('getBillingAllServerlessDeploymentsByTimeRange response', response);
            // setBillingAllServerlessDeployments(response as BillingServerlessItems);
            // update the tab's items (keys)
            const updatedBillingTab = breakdownTabs?.map(tab => {
                if (tab && tab.label === 'Serverless') {
                    tab.data.deployments = response as BillingServerlessItems;
                }
                return tab;
            });
            setBreakdownTabs(updatedBillingTab);
        }).catch((e: any) => {
            Logger.log(e);
        });

        // Fetch dedicated deployments
        FetchBillingAPI.getBillingAllDedicatedByTimeRange(startDate, endDate).then((response: BillingDedicatedItems) => {
            Logger.log('getBillingAllDedicatedByTimeRange response', response);
            const updatedBillingTab = breakdownTabs?.map(tab => {
                if (tab && tab.label === 'Dedicated') {
                    tab.data.deployments = response as BillingDedicatedItems;
                }
                return tab;
            });
            setBreakdownTabs(updatedBillingTab);
        }).catch((e: any) => {
            Logger.log(e);
        });

    //   const date = new Date();
    //   date.setMonth(date.getMonth() + 1);
    //   setEndDate(date.getMonth()+'/'+date.getDate()+'/'+date.getFullYear());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);

    React.useEffect(() => {
        const serverless = breakdownTabs[0];
        const keys = (serverless?.data as BillingServerless).keys;
        const dedicated = breakdownTabs[1];
        const deployments = (dedicated?.data as BillingDedicated).deployments;
        const serverlessCost = keys?.cost.toFixed(2);
        const dedicatedCost = deployments?.cost.toFixed(2);
        let total = Number(serverlessCost) + Number(dedicatedCost);
        const sum = total && !isNaN(total) ? total < .01 ? `< $0.01`: `$${total.toFixed(2)}` : '$0';
        setTotalUsage(sum);
    }, [breakdownTabs]);

    // React.useEffect(() => {
    //     if(searchParams.get("isSubscriptionSuccess") && searchParams.get("isSubscriptionSuccess") === 'true') {
    //         setIsSubscriptionSuccess(true);
    //     }

    //     if(searchParams.get("isSubscriptionCancelled") && searchParams.get("isSubscriptionCancelled") === 'true') {
    //         setIsSubscriptionCancelled(true);
    //     }
        
    // }, [searchParams, setIsSubscriptionSuccess, setIsSubscriptionCancelled]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    // const handleCreateSubscription = (event: React.MouseEvent<HTMLElement>) => {
    //     //event.currentTarget
    //     FetchBillingAPI.createSubscription().then((response: String) => {
    //         console.log('createSubscription response', response);
    //         if (response) {
    //            window.location.href = response.toString();
    //         }
    //     }).catch((e: any) => {
    //         console.log(e);
    //     });
    // };

    // console.log('isSubscriptionSuccess/isSubscribed', isSubscriptionSuccess, isSubscribed);
    return (<>
        {subText &&
            <p className="flex items-center text-sm text-blue-400">
                {subText}
            </p>
        }

        {/* <Grid size={{ xs: 6, md: 6 }} sx={{ mt: 1, maxWidth: "300px" }}> */}
        <Grid container spacing={1}>
            <Grid size={{ xs: 12, md: 3 }} className="overview-left-panel">
                <div className="items-center text-med text-gray-800  ">
                    <Typography variant="subtitle2" component="div">
                        Select date range
                    </Typography>
                </div>
                <Datepicker
                    inputClassName="bg-white border-gray-200 w-full rounded-md font-normal"
                    primaryColor={"sky"}
                    showFooter={true}
                    showShortcuts={true}
                    value={dateRange} 
                    onChange={newValue => {
                        if(newValue) {
                            setDateRange(newValue as DateRangeType)
                        }
                    }}
                    // configs={{
                    //     shortcuts: {
                    //         today: "TText",
                    //         yesterday: "YText",
                    //         past: period => "P-"+ period + " text",
                    //         currentMonth: "CMText",
                    //         pastMonth: "PMText"
                    //     },
                    //     footer: {
                    //         cancel: "CText",
                    //         apply: "AText"
                    //     }
                    // }}
                />
            </Grid>
            <Grid size={{ xs: 12, md: 9 }} className="overview-right-panel">
                <Card variant="outlined" sx={{ mt: 3, maxWidth: 360 }}>
                    <Box sx={{ p: 1, minWidth: 320  }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle2" component="div">
                                Current cost
                            </Typography>
                            <Typography variant="subtitle2" component="div">
                                <span className="font-bold flex items-center text-med text-blue-400">{totalUsage}</span>
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
            </Grid>
        </Grid>

        {/* <Stack direction="row" spacing={1} sx={{ mt: 3 }} style={{maxWidth: '750px'}}> */}
            {/* <Card variant="elevation" sx={{ maxWidth: 360 }}>
                <Box sx={{ p: 2, minWidth: 320  }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1" component="div">
                            Current cost
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                            <span className="font-bold flex items-center text-med text-blue-400">{totalUsage}</span>
                        </Typography>
                    </Stack>
                </Box>
            </Card> */}
            {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
            {/* <Card variant="elevation" sx={{ maxWidth: 360 }}>
                <Box sx={{ p: 2, minWidth: 320}}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1" component="div">
                            Ends on
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                            <span className="font-bold flex items-center text-med text-blue-400">{endDate}</span>
                        </Typography>
                    </Stack>
                </Box>
            </Card> */}
        {/* </Stack> */}

        {/* <Stack direction="row" spacing={1} sx={{ mt: 3 }} style={{maxWidth: '750px'}}>
            <Card variant="elevation" sx={{ maxWidth: 360 }}>
                <Box sx={{ p: 2, minWidth: 320  }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Current credit
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            <span className="font-bold flex items-center text-med text-blue-400">$5.00</span>
                        </Typography>
                    </Stack>
                </Box>
            </Card>
            <Card variant="elevation" sx={{ maxWidth: 360 }}>
                <Box sx={{ p: 2, minWidth: 320}}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Autopayment mode
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            <span className="font-bold flex items-center text-med text-blue-400">On</span>
                        </Typography>
                    </Stack>
                </Box>
            </Card>
        </Stack> */}

        {/* current period */}
        {/* <Grid xs={12} md={12} sx={{ mt: 5 }}>
            <Grid xs={6} md={12}>
                <div className="py-1 flex items-center text-med text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6  ">
                    <p style={{marginRight: "5px"}}>Current Period - <span className="font-bold">JUL 1 - JUL 31</span></p>
                </div>
                <p style={{height: '200px', width: '100%', border: '1px solid #CCC', backgroundColor: '#DDD', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    No Usage info
                </p>
            </Grid>
        </Grid> */}

        {/* invoice */}
        {/* <Grid xs={12} md={12} sx={{ mt: 5 }}>
            <Grid xs={6} md={12}>
                <div className="py-1 flex items-center text-med text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6  ">
                    <p style={{marginRight: "5px"}}>Invoice</p>
                </div>
                <div className="flex w-full items-center justify-center bg-gray-600 py-2">
                    <div className="w-80 rounded bg-gray-50 px-6 pt-8 shadow-lg">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg" alt="chippz" className="mx-auto w-16 py-4" />
                        <div className="flex flex-col justify-center items-center gap-2">
                            <h4 className="font-semibold">Parasail</h4>
                            {/* <p className="text-xs">Some address goes here</p>
                        </div>
                        <div className="flex flex-col gap-3 border-b py-6 text-xs">
                        <p className="flex justify-between">
                            <span className="text-gray-400">Receipt No.:</span>
                            <span>#999</span>
                        </p>
                        <p className="flex justify-between">
                            <span className="text-gray-400">Type:</span>
                            <span>Serverless/Dedicated</span>
                        </p>
                        <p className="flex justify-between">
                            <span className="text-gray-400">Account:</span>
                            <span>Company 1</span>
                        </p>
                        </div>
                        <div className="flex flex-col gap-3 pb-6 pt-2 text-xs">
                        <table className="w-full text-left">
                            <thead>
                            <tr className="flex">
                                <th className="w-full py-2">Product</th>
                                <th className="min-w-[44px] py-2">QTY</th>
                                <th className="min-w-[44px] py-2">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className="flex">
                                <td className="flex-1 py-1">Serverless</td>
                                <td className="min-w-[44px]">4 tkns</td>
                                <td className="min-w-[44px]">$120</td>
                            </tr>
                            <tr className="flex py-1">
                                <td className="flex-1">Dedicated</td>
                                <td className="min-w-[44px]">4 hrs</td>
                                <td className="min-w-[44px]">$200</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className=" border-b border border-dashed"></div>
                        <div className="py-4 justify-center items-center flex flex-col gap-2">
                            <p className="flex gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"><path d="M21.3 12.23h-3.48c-.98 0-1.85.54-2.29 1.42l-.84 1.66c-.2.4-.6.65-1.04.65h-3.28c-.31 0-.75-.07-1.04-.65l-.84-1.65a2.567 2.567 0 0 0-2.29-1.42H2.7c-.39 0-.7.31-.7.7v3.26C2 19.83 4.18 22 7.82 22h8.38c3.43 0 5.54-1.88 5.8-5.22v-3.85c0-.38-.31-.7-.7-.7ZM12.75 2c0-.41-.34-.75-.75-.75s-.75.34-.75.75v2h1.5V2Z" fill="#000"></path><path d="M22 9.81v1.04a2.06 2.06 0 0 0-.7-.12h-3.48c-1.55 0-2.94.86-3.63 2.24l-.75 1.48h-2.86l-.75-1.47a4.026 4.026 0 0 0-3.63-2.25H2.7c-.24 0-.48.04-.7.12V9.81C2 6.17 4.17 4 7.81 4h3.44v3.19l-.72-.72a.754.754 0 0 0-1.06 0c-.29.29-.29.77 0 1.06l2 2c.01.01.02.01.02.02a.753.753 0 0 0 .51.2c.1 0 .19-.02.28-.06.09-.03.18-.09.25-.16l2-2c.29-.29.29-.77 0-1.06a.754.754 0 0 0-1.06 0l-.72.72V4h3.44C19.83 4 22 6.17 22 9.81Z" fill="#000"></path></svg> invoice@parasail.io</p>
                            <p className="flex gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"><path fill="#000" d="M11.05 14.95L9.2 16.8c-.39.39-1.01.39-1.41.01-.11-.11-.22-.21-.33-.32a28.414 28.414 0 01-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 8.67 2 7.58 2 6.54c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67C4.15 2.31 4.85 2 5.59 2c.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.1.1.21.2.31.3.4.39.41 1.03.01 1.43zM21.97 18.33a2.54 2.54 0 01-.25 1.09c-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.01 0-.02.01-.03.01-.59.24-1.23.37-1.92.37-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98c-.39-.29-.78-.58-1.15-.89l3.27-3.27c.28.21.53.37.74.48.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78z"></path></svg> +234XXXXXXXX</p>
                        </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid> */}

        {/* endpoints breakdown */}
        <Grid className="bg-white" size={{ xs: 12, md: 12 }} sx={{ mt: 1 }}>
            <Grid size={{ xs: 6, md: 12 }}>
                {/* <div className="py-1 flex items-center text-med text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6  ">
                    <p style={{marginRight: "5px"}}>Endpoints breakdown</p>
                </div> */}
                <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            { breakdownTabs?.map((tab, index) => {
                                const indexFormatted = index + 1;
                                return (
                                    <Tab key={`tab-${indexFormatted.toString()}`} label={tab.label} value={indexFormatted.toString()} sx={{textTransform :"none"}} />
                                ); 
                            })}
                            </TabList>
                        </Box>
                        { breakdownTabs?.map((tab, index) => {
                            const indexFormatted = index + 1;
                            return (
                            <TabPanel key={`tab-${indexFormatted.toString()}`} value={indexFormatted.toString()} sx={{padding: "5px"}}>
                                {tab.label === 'Serverless' ? (<>
                                    <tab.component data={tab.data} dateRange={dateRange} />
                                </>
                                ):
                                (<>
                                    <tab.component data={tab.data} />
                                </>)}
                            </TabPanel>
                            ); 
                        })}
                        </TabContext>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    </>);
}

export default BillingOverview;
